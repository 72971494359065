@import 'react-mosaic-component/react-mosaic-component';

.mosaic-blueprint-theme {
  background: #e4e7ea !important;

  .mosaic-window-toolbar {
    height: 50px !important;
  }
  .mosaic-window {
    // a bit more subtle border than the default one
    background-color: #fff !important;
    background-clip: border-box !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0.25rem !important;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  }
}
